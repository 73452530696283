.desktop-menu{
  display: none;
}
.desktop-menu ul{
  margin:0;
  padding: 0;
  list-style-type: none;
  display: flex;
}
.desktop-menu li{
  padding: 0 0.5em;
  text-align: center;
}
.desktop-menu a{
  text-decoration: none;
}

@media (min-width: 768px){
  .desktop-menu{
    display: block;
  }
}