.mobile-menu aside{
  position: fixed;
  top: 70px;
  /*right: 0;*/
  width: 50vw;
  min-width: 320px;
  height: calc(100vh - 70px);
  /*transition: 300ms ease-in right;*/
}
/*.mobile-menu aside.hide{
  right: -100vw;
}*/
.mobile-menu-content-enter{
  right: -100vw;
}
.mobile-menu-content-enter-active{
  right: 0;
  transition: 300ms ease-in right;
}
.mobile-menu-content-enter-done{
  right: 0;
}
.mobile-menu-content-exit{
  right: 0;
}
.mobile-menu-content-exit-active{
  right: -100vw;
  transition: 300ms ease-in right;
}
.mobile-menu ul{
  margin: auto;
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 55%;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}
.mobile-menu li{
  text-align: center;
}
.mobile-menu a{
  display: block;
  padding: 0.5em 0;
  text-decoration: none;
}
.mobile-menu .menu-icon{
  font-size: 1em;
}
.mobile-menu .ion-md-menu{
  font-size: 2em;
}

@media (min-width: 768px){
  .mobile-menu{
    display: none;
  }
}