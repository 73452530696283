.tyc{
  display: block;
  padding-top: 70px;
}
.description{
  width: 80%;
  margin: auto;
}
.doubts{
  text-align: center;
}