.detail{
  display: block;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 70px);
  background: white;
  background-blend-mode: hard-light;
  top: 70px;
  left: 0;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}
.detail .close{
  position: fixed;
  top: 70px;
  right: 1em;
  background: #FFF;
  padding: 0.5em 1em;
  font-size: 1.5em;
  font-weight: 700;
  color: #000;
  text-decoration: none;
}
.detail .imageContainer{
  min-height: 35vh;
  background: rgb(78,9,43);
  background: linear-gradient(90deg, rgba(78,9,43,1) 0%, rgba(121,9,68,1) 15%, rgba(255,0,121,1) 100%);
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  color: white;
  text-shadow: rgba(0,0,0,0.5) 1px 1px 10px;
}

.detail .description{
  color: #FFF;
  padding: 0.5em;
}

.detail .description h2{
  margin: 0;
  font-weight: lighter;
  font-size: 1.3em;
}

.detail .description h3{
  margin: 0.5em 0 0;
}

.gallery{
  list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.gallery li{
  flex: 1 0 50%;
  flex-wrap: wrap;
  height: 50%;
  background-size: cover;
  background-position: center;
}

.gallery li img{
  width: 100%;
}

.detail .content{
  padding: 1em;
}

.detail .content p{
  text-align: center;
}

.detail .content > ol li{
  text-align: left;
  padding: 0 1em;
}

.detail .content > ol li > ol{
  padding: 0 0.5em;
}

.detail .content > ol li > ol li{
  list-style-type: lower-alpha;
  width: 80vw;
}

.paymentContainer{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5em;
}

.paymentInfo{
  background: #eee;
  padding: 1em;
  flex: 1 1 100%;
}

.paymentInfo a{
  color: black;
  font-weight: bold;
  text-decoration: none;
}

.formContainer{
  background: #aaa;
  padding: 1em;
  flex: 1 1 100%;
}

.doubts{
  text-align: center;
  padding: 1em;
}
.doubts a{
  text-decoration: none;
  font-weight: bold;
  color: #333;
}

.button{
  width: 100%;
    margin: 0.5em auto 1.5em;
    padding: 1em;
    background: #000;
    color: #FFF;
    border: none;
    font-size: 1em;
    font-weight: bold;
    display: block;
    cursor: pointer;
}

@media (min-width: 720px){
  .detail .imageContainer{
    text-align: right;
    justify-content: flex-end;
    font-size: 2em;
    padding-right: 2em;
  }
  .detail .content{
    font-size: 1.3em;
    width: 75vw;
    margin: auto;
  }
  .detail .content p, .detail .content h3{
    text-align: left;
  }
  .detail .content > ol li{
    font-size: 1em;
    width: 100%;
  }
  .gallery{
    list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      min-height: 50%;
      flex-direction: row;
  }
  
  .gallery li{
    flex: 1 0 50%;
    flex-wrap: wrap;
    height: 35vw;
    background-size: cover;
    background-position: center;
  }
  .button{
    width: 50vw;
  }
  .doubts{
    width: 75vw;
    margin: 2em auto;
  }
  .paymentInfo{
    flex: 1;
  }

  .formContainer{
    flex: 2;
  }
}