.galleryItem{
  position: relative;
}
.galleryItem .photo{
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 40vh;
  width: 100%;
  position: relative;
}
.galleryItem .caption{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  background: rgba(0,0,0,0.55);
}
.galleryItem .caption p{
  margin: 0;
  font-size: 1em;
}
.galleryItem .caption h3, .galleryItem .caption h2{
  font-size: 1.2em;
  margin-bottom: 0.2em;
}
.galleryItem .caption a{
  font-size: 1em;
  text-decoration: none;
  display: block;
  color: #FFF;
  border: 1px solid #FFF;
  padding: 0.5em 1.5em;
  border-radius: 10px;
}

@media (min-width: 720px){
  .galleryItem .photo{
    height: 25vh;
  }
  .galleryItem .caption{
    top: 0;
    height: 100%;
    opacity: 0;
    transition: 400ms ease-out opacity;
    background: rgba(0,0,0,0.85);
  }
  .galleryItem .caption:hover{
    opacity: 1;
  }
  .galleryItem .caption p{
    font-size: 1.2em;
  }
  .galleryItem .caption h3, .galleryItem .caption h2{
    font-size: 1.5em;
    margin: 1em 0;
  }
}