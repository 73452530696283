.about {
  min-height: 75vh;
  text-align: center;
  background-size: cover;
  padding-top: 80px;
}

.about h2,
.about h3,
.about h4,
.about h5 {
  margin-top: 0;
  margin-bottom: 0.2em;
}
.about p,
.about ul {
  margin: 1em auto;
  width: 85vw;
  text-align: left;
}
.about .image {
  width: 100%;
  height: 50vh;
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5em;
}

@media (min-width: 720px) {
  .about h2 {
    text-align: left;
    padding-left: 10vw;
    margin: 1em 0;
  }
  .about h3,
  .about h4,
  .about h5 {
    text-align: left;
    padding-left: 15vw;
    margin: 1em 0;
  }
  .about p,
  .about ul {
    width: 70vw;
  }
}
