.home{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-blend-mode: multiply;
}

.home h2{
  margin-top: 0;
  margin-bottom: 0.2em;
}
.home p{
  margin: 0;
}

.home .callToAction{
  margin: 2em auto 0;
  text-transform: capitalize;
  font-weight: bold;
  display: block;
  padding: 0.5em 1em;
  text-align: center;
  text-shadow: none;
  text-decoration: none;
  border-radius: 10px;
  width: 90vw;
  max-width: 300px;
}