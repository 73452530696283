.contact {
  min-height: 75vh;
  text-align: center;
  background-size: cover;
  padding-top: 80px;
}

.contact h2 {
  margin-top: 0;
  margin-bottom: 0.2em;
}
.contact p {
  margin: 1em auto;
  width: 85vw;
  text-align: left;
}
.contact .tangible {
  width: 90vw;
  min-width: 320px;
  text-align: left;
  border-radius: 10px;
  margin: 0 auto 0.5em;
  padding: 0.5em;
}
.contact .tangible .address {
  margin-bottom: 1em;
}
.contact .tangible .address p {
  margin: 0;
  width: 100%;
}
.contact .networks {
  width: 100vw;
  margin: auto;
}
.contact .networks a {
  text-decoration: none;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  min-width: 320px;
  margin: 0 auto 0.5em;
  transition: 400ms ease-out background-color;
}
.contact .networks a:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.contact .networks a span {
  margin-right: 0.3em;
  font-size: 1.5em;
}

@media (min-width: 720px) {
  .contact h2 {
    text-align: left;
    padding-left: 10vw;
    margin: 1em 0;
  }
  .contact p {
    width: 70vw;
  }
  .contact .tangible {
    width: 55vw;
    padding: 1.5em;
  }
  .contact .networks {
    width: 70vw;
    margin: auto;
  }
  .contact .networks a {
    width: 80%;
  }
}

@media (min-width: 960px) {
  .contact .content {
    display: flex;
    width: 80vw;
    margin: auto;
  }
}
