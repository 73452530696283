.portfolio {
  min-height: 75vh;
  text-align: center;
  background-size: cover;
  padding: 1em;
  padding-top: 80px;
}
.portfolio h2 {
  margin-top: 0;
  margin-bottom: 0.2em;
  color: #000;
}
.portfolio > p {
  width: 85vw;
  margin: auto;
}
.portfolio .portfolioList {
  list-style-type: none;
  padding: 0;
  width: 100%;
}
.portfolio .portfolioList li {
  width: auto;
}

@media (min-width: 720px) {
  .portfolio h2 {
    margin: 1em 0;
  }
  .portfolio .portfolioList {
    display: grid;
    grid-template-columns: repeat(3, minmax(235px, 1fr));
    justify-content: center;
    gap: 1em;
  }
  .portfolio .portfolioList li {
    font-size: 0.8em;
  }
  .portfolio .portfolioList li > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
