header{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 70px;
  padding: 0 1em;
}
header nav{
  font-size: 0.9rem;
  justify-content: space-between;
  align-items: center;
  display: flex;
  height: 100%;
  padding: 10px;
}

header .logo{
  height: 100%;
}