*{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error-boundary a{
  color: #999;
  display: block;
  text-align: center;
}
.error-boundary h2{
  width: 50vw;
  margin: 1em auto;
  color: #333;
  text-align: center;
}
.error-boundary h2 > a{
  display: inline;
  text-decoration: none;
}


.hidden{
  display: none;
}
